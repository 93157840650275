
.btn-orange {
  font-size: 12px;
  padding: $gutter/4 $gutter/2;
  // height: 18px;
  line-height: 18px;
  display: inline-block;
  text-transform: uppercase;
  background: $primary-color;
  color: $color-white;
  font-family: $oswald;
  transition: all 0.25s ease;
  &:visited, &:hover, &:active, &:focus {
     background-color: darken($primary-color, 10%);
     color: #ffffff !important;
     text-decoration: none;
  }
}

.to-top {
  background-color: $tertiary-color;
  margin-top: $gutter/4;
  display: inline-block;
  color: white;
  padding: $gutter/4 $gutter/2;
  font-size: 12px;
  text-transform: uppercase;
  font-family: $oswald;
  &:hover, &:active {
    background-color: $primary-color;
    color: white;
    &:visited {
      color: white;
    }
  }
  &:visited {
    color: white;
  }
}

/**
 * News View button
 */
.view-id-news {
  .view-header {
    a {
      color: white;
      background: #ea3e10;
      padding: $gutter/4 $gutter/2;
      font-family: $oswald;
      text-transform: uppercase;
    }
    a:hover {
      background: $secondary-color;
    }
  }
}

input[value="Delete"] {
  @include submit-btn($color-delete);
}

input[value="Remove"] {
  @include submit-btn($btn-color: $primary-color, $btn-padding: 2px 7px, $font-size: 12px);
}
