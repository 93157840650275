//
// Product Subsection List Page
//
// @url en/catalog/103

.view-section-detail-featured .view-content {
  border: 1px solid $primary-color;
  margin-bottom: $gutter;

  // overflow: hidden;
  &>div {
    @include clearfix;
  }

  .views-field-title-field {
    float: right;
    width: 33.3333%;
    font-size: 1.6em;
    padding: $gutter;
    line-height: 1.6em;

    a {
      font-family: $oswald;
      color: $secondary-color;
      text-transform: uppercase;
    }
  }

  .product-feature-image {
    float: left;
    width: 66.6666%;

    img {
      width: 100%;
    }
  }

  .product-subsection-title a {
    text-align: left;
  }

  @include breakpoint($tablet-portrait-max) {

    .views-field-title-field,
    .product-feature-image {
      width: 100%;
    }
  }
}


//
// Product Teaser
//



.product-teaser {
  // border: 1px solid $color-grey-title-bar;
  // padding-bottom: 12px;
  color: white;

  .field-kit-description,
  .product-info,
  .product-qty {
    padding: 0 12px;
  }

  select.rolodex {
    width: 313px;
  }

  .add-to-cart {
    text-align: right;
  }

  .form-actions,
  .form-type-uc-quantity {
    display: inline-block;
  }

  .attributes {}
}

//
// Product Pages
//

.field-description-overview {
  ul {
    overflow: hidden;

    li {
      list-style-type: disc;
    }
  }
}

.view-mode-product_teaser li {
  list-style-type: disc;
}


.sell-price {
  font-size: 1.3em;
  font-weight: bold;
  text-align: center;
}

.node-product-kits.view-mode-full,
.node-product.view-mode-full {
  .add-to-cart {
    border: 1px dashed #e3e3e3;
    padding: 20px 10px;
    float: right;
    clear: right;
    width: 212px;
    text-align: center;

    .form-actions {
      display: inline-block;
      vertical-align: baseline;

      input {
        line-height: 24px;
      }
    }
  }

  .form-item-qty label {
    display: none;
  }

  .form-item-qty {
    display: inline-block;
    margin-bottom: 0;
    vertical-align: baseline;
  }

  .field-image-cache {
    //width: 212px;
    margin: 0 auto;
    //float: right;
    clear: right;
    margin-bottom: 10px;
  }

  .model {
    clear: right;
    width: 212px;
    text-align: center;
    float: right;
    display: block;
    margin-right: 0;
    font-weight: normal;
    margin-bottom: 10px;
  }

  .display-price,
  .sell-price {
    width: 212px;
    text-align: center;
    float: right;
    clear: right;
    padding-left: 0;
    color: $color-orange;
    padding-bottom: 0;
    margin-bottom: 10px;
  }

  .body {
    padding-right: 20px;
    margin: 0 0 20px 0;
    float: left;
  }
}

//
// Multi-Product
//

#block-views-product-detail-image-block {
  float: right;
  margin: 0 0 24px 24px;
  background-color: #fff;
  border: 1px solid $color-grey-title-bar;
  width: 345px;

  .views-field-title {
    border-bottom: 1px solid #dddddd;
    background-color: #dddddd;
    font-size: 14px;
    text-align: center;
    padding: 4px 12px;
    font-weight: bold;
  }
}

.node-system-product-line.view-mode-full {
  .node-title {
    margin-bottom: 16px;
    font-size: 18px;
  }
}

//
// Product Kit Page
//

.node-type-system-product-line {
  .node__title {
    margin-bottom: 15px;
  }

  .field-product-line-name-image {
    img {
      width: 30%;
      padding-top: 2px;
      padding-bottom: 15px;
    }
  }

  #block-views-related-products-block {
    clear: both;
    margin-top: 20px;
    float: left;
    width: 100%;
    margin-bottom: 0px;

    .flexslider {
      margin-bottom: 0px;
    }
  }

  #block-productlinegallery-youtube-gallery {
    margin-bottom: 0px;

    #flexslider-video-gallery-display {
      margin-top: 15px;
      margin-bottom: 5px;

      img {
        // width: 345px;
        border: 1px solid #abafb2;
      }

      .flex-active-slide {
        // width: 100% !important;
      }
    }

    .flex-direction-nav a {
      line-height: 43px;
    }
  }

  #block-productlinegallery-youtube-gallery {
    margin-bottom: 0px;

    #flexslider-video-gallery-thumb {
      margin-bottom: 0px;

      .flex-viewport {
        height: auto !important;
        clear: both;

        ul>li {

          // margin-right: 5px;
          img {
            // border: 1px solid #abafb2;
          }
        }

        li:last-child {
          margin-right: 0px;
        }
      }
    }
  }

  .node-system-product-line {
    margin-bottom: $gutter;
  }

  #block-garrisonblocks-learning-center {
    overflow: hidden;
    width: 344px;

    .learning-center-document {
      overflow: hidden;
      padding-top: 6px;
      padding-bottom: 6px;

      p {
        float: left;
        color: $color-grey-mid;
      }

      a {
        float: right;
      }
    }
  }
}

#flexslider-video-gallery-thumb {
  .slides>li {
    cursor: pointer;

    &.flex-active-slide {
      cursor: default;
    }

    img {
      width: auto;
    }
  }
}

.view-related-educational-resources .view-empty {
  margin-top: 12px;
}

.view-product-kit-display {
  .view-mode-product_teaser {
    overflow: hidden;

    .field-kit-description {
      margin: 8px 0 8px 8px;
    }

    .body {
      overflow: visible;

      span {
        background: none !important;
      }
    }

    .attributes {
      label {
        float: left;
        margin-top: 7px;
        margin-right: 6px;
      }

      .selector {
        width: 137px !important;
        margin: 2px 12px 2px 2px;
      }
    }
  }
}

.view-related-educational-resources {
  .item-list ul {
    margin-left: 0;
    padding-left: 0;

    li {
      list-style-type: none;
      list-style-image: none;
      margin-bottom: 8px;
    }
  }

  .views-row {
    margin-bottom: $gutter/2;
  }

  .related-edu-title {
    margin-bottom: 10px;
    font-weight: normal;
    font-size: 14px;
    width: 100%;
  }

  .related-edu-slideshow {
    display: inline-block;
    width: 20px;
    padding-top: $gutter/4;

    a {
      text-indent: -9999em;
      display: block;

      &:before {
        content: '\f24d';
        display: block;
        font-family: fontAwesome;
        color: $primary-color;
        text-indent: 0px;
        font-size: 1.25em !important;
        line-height: 0;
      }

      &:hover {
        cursor: pointer;
      }
    }
  }

  .slideshow.link {
    text-indent: -999999em;
  }

  .slideshow-icon {
    display: none;
  }

  .related-edu-doc {
    display: inline-block;
    width: 20px;
    padding-top: $gutter/4;

    a {
      text-indent: -9999em;
      display: block;

      &:before {
        content: '\f1c1';
        display: block;
        font-family: fontAwesome;
        color: $primary-color;
        text-indent: 0px;
        font-size: 1.25em !important;
        line-height: 0;
      }

      &:hover {
        cursor: pointer;
      }
    }

    .icon-pdf {
      display: none;
    }
  }

}



.related-edu-slideshow {
  .slideshow {
    width: 21px;
    height: 16px;
    display: block;
  }

  .slideshow-icon {
    width: 21px;
    height: 16px;
    background-image: url("../images/slideshow-single1x.png");
    background-repeat: no-repeat;
    background-position: 0 0;
  }
}

@media only screen and (-webkit-min-device-pixel-ratio: 2),
only screen and (min--moz-device-pixel-ratio: 2),
only screen and (-o-min-device-pixel-ratio: 2/1),
only screen and (min-device-pixel-ratio: 2),
only screen and (min-resolution: 192dpi),
only screen and (min-resolution: 2dppx) {
  .view-related-educational-resources .slideshow-icon {
    background-image: url('../images/slideshow-single2x.png');
    background-size: 21px 16px;
  }

  html.lt-ie9 .view-related-educational-resources .slideshow-icon {
    background-image: url('../images/slideshow-single1x.png');
  }

}

/* END RETINA MEDIA QUERY */

/* Multi-product list table */
#block-multiproductlist-multi-list {
  clear: both;
}

.product-line-info {
  clear: both;
  margin-top: $gutter;
  border-top: 1px solid $color-grey-light;
  border-bottom: 1px solid $color-grey-light;
  padding: $gutter*1.5 0;

  @include breakpoint($desktop-min) {
    display: flex;

    &>div {
      padding-right: $gutter;
      width: 50%;
      min-width: 33.3333%;
      margin: 0;

      &:last-child {
        padding-right: 0;
      }
    }
  }

  @include breakpoint($desktop-max) {
    #block-productlinegallery-gallery {
      width: 50%;
      float: left;
      padding-right: $gutter;
    }

    #block-productlinegallery-youtube-gallery {
      width: 50%;
      float: left;
    }

    #block-views-3e2637c6b89f28f44519818e6b2b75f8 {
      width: 100%;
      clear: both;
      padding-top: $gutter*2;
    }
  }

  @include breakpoint($tablet-small-max) {
    &>div {
      width: 100% !important;
      padding: 0 0 $gutter*2 !important;
      clear: both;

      &:last-child {
        padding-bottom: 0px !important;
      }
    }
  }
}


// Responsive slider video gallery fix

.mejs-container,
.youtube_video {
  width: 100% !important;
  height: 100% !important;

  //   // height: auto !important;
  .mejs-inner {

    .mejs-layers,
    .mejs-mediaelement,
    .mejs-clear,
    .me-cannotplay {
      width: 100% !important;
      height: 100% !important;
    }

    //   //   position: relative;
    //   //   height: 0px;
    //   //   padding-bottom: 62.5%;
    //   //   .mejs-mediaelement {
    //   //     position: absolute;
    //   //     top:0;
    //   //     right:0;
    //   //     bottom:0;
    //   //     left: 0;
    //   //   }
  }
}


.mejs-mediaelement {
  width: 100%;
  position: relative;
  height: 0px;
  padding-bottom: 62.5%;

  .mediaelement-processed,
    {
    width: 100% !important;
    height: 100% !important;
    @include absolute-position(0, 0, 0, 0);
  }
}

.mejs-layers {
  @include absolute-position(0, 0, 0, 0);

  &>div {
    width: 100% !important;
    height: 100% !important;
  }

  .mejs-overlay-button {
    transform: translate(-50%, -58%);
    margin: 0 !important;
  }
}

.mejs-controls {
  width: 100% !important;
  @include absolute-position(auto, 0, 0, 0);

  &>div {
    // @include absolute-position(0,0,0,0);
    box-sizing: border-box;
  }
}

.node-type-system-product-line #content-body {
  @include breakpoint($tablet-portrait-max) {
    display: flex;
    flex-wrap: wrap;

    #block-views-product-kit-display-block,
    #block-multiproductlist-multi-list {
      order: 2;
    }

    .node-system-product-line {
      order: 1;
    }

    .field-distributor-us-link,
    .field-distributor-canada-link,
    .field-distributor-europe-link,
    .field-distributor-row-link {
      ordeR: 3;
    }

    .product-line-info {
      order: 4;
    }

    #block-views-related-products-block {
      order: 5;
    }
  }
}

// Less/More styles
.reveal-button,
.hide-button {
  font-family: $oswald;
  text-transform: uppercase;

  & a {
    &:hover {
      color: $secondary-color;
      cursor: pointer;
    }

    &:after {
      content: '';
      font-family: fontAwesome;
      display: inline-block;
      padding-lefT: 5px;
    }
  }
}

.reveal-button {
  & a:after {
    content: '\f107';
  }
}

.hide-button {
  & a:after {
    content: '\f106';
  }
}

.hide-this {
  display: none;
}
