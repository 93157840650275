/**
 * The language switcher / dropdown
 **/

  #block-menu-secondary-menu {
    position: relative;

    li.menu-3104,
    li.menu-3161,
    li.menu-3106,
    li.menu-3162,
    li.menu-3107,
    li.menu-3163,
    li.menu-3171,
    li.menu-3221,
    li.menu-3197 {
      a {
        color: transparent;
        width: 25px;
        height: 20px;
        padding: 0px;
        margin: 0px;
        display: inline-block;
      }
    }

    li.menu-3104 a,
    li.menu-3161 a {
      background: transparent url('../images/lang/US.png') no-repeat scroll 0px 0px;
    }
    li.menu-3106 a,
    li.menu-3163 a  {
      background: transparent url('../images/lang/IT.png') no-repeat scroll 0px 0px;
    }
    li.menu-3107 a,
    li.menu-3162 a{
      background: transparent url('../images/lang/DE.png') no-repeat scroll 0px 0px;
    }
    li.menu-3171 a {
      background: transparent url('../images/lang/FR.png') no-repeat scroll 0px 0px;
    }
    li.menu-3221 a, li.menu-3197 a {
      background: transparent url('../images/lang/ES.png') no-repeat scroll 0px 0px;
    }

    /*.language-switcher + ul.menu {

      position: absolute;
      top: 25px;
      right: -25px;
      width: 137px;
      background-color: $color-grey-light;
      z-index: 99;
      padding: 2px 10px 5px;
      display: none;

      li {
        display: block;
        float: none;
        position: relative;
        padding: 5px;

        a {
          float: none;
        }

      }


    }*/

  }
