
.page-node-370{
   table.gd-form{
      margin-top: 0;
   }
   table td{
      font-size: 14px;
   }
   tbody tr{
      background-color: #efefef;
   }
   tr.stripe{
      background-color: #dedede;
   }
   table.gd-form{
      img{
         margin-top:5px;
      }
   }
}
