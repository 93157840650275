#header-wrapper {
  background: $color-grey-light;
  .region-header {
    padding-top: 10px;
    padding-bottom: 10px;
    @include clearfix;
  }
  @include breakpoint($tablet-small-max) {
    .region-header {
      visibility: hidden;
      margin: 0;
      padding: 0;
    }
  }
}