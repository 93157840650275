#block-search-form {
  margin: 0;
}


// Social media links

#block-block-26 {
  float: left;
  margin: 0;
  @include breakpoint($tablet-small-max) {
    display: none;
  }
}

.social-media-icons {
  margin: 0;
  padding: 0;
  height: 28px;
  li {
    display: inline-block;
    list-style-type: none;
    margin-right: 6px;
    a {
      display: block;
      vertical-align: middle;
      font-size: 0px;
      width: 28px;
      height: 28px;
      background: $color-grey;
      transition: all 0.25s ease;
      &.facebook:before {
        content: '\f09a';
      }
      &.twitter:before {
        content: '\f099';
      }
      &.youtube:before {
        content: '\f16a';
      }
      &.email:before {
        content: '\f0e0';
      }
      &:before {
        content: '';
        font-family: fontAwesome;
        font-size: 16px;
        text-align: center;
        display: block;
        color: white;
        position: relative;
        top: 4px;
        // background: $color-grey;
        // width: 28px;
        // height: 28px;
      }
      &:hover {
        background: $primary-color;
      }
    }
  }
}

// Homepage Intro Block
#block-block-28 {
  background: $color-grey;
  padding: $gutter;
  h2 {
    color: white;
    border-bottom: 0px;
    font-size: 1.6em;
  }
  p {
    color: white;
    font-size: 1.25em;
    line-height:1.6em;
  }
}

// Latest News sidebar block
#block-views-news-sidebar-block {
  background: $secondary-color;
  padding: $gutter;
  .block-title {
    text-align: center;
  }
  .view-news h2 {
    margin: 0;
  }
  ul.ui-list {
    margin-bottom: 0;
  }
  h2.field-content {
    font-size: 1em;
    font-family: $source-sans-pro;
    a {
      color: white;
      font-weight: bold;
      text-transform: none;
      &:hover {
        color: $primary-color;
      }
    }
  }
  h2 {
    color: white;
  }
  li:last-child {
    margin-bottom: 0;
  }
}

// Contact Block
.contact-block {
  padding-right: $gutter;
  div {
    padding-bottom: $gutter/2;
    p {
      padding-left: $gutter;
      &:before {
        color: $primary-color;
        font-family: fontAwesome;
        display: inline-block;
        width: $gutter;
        margin-left: -$gutter;
      }
    }
    &#address p:before {
      content: '';
    }
    &#phone p:before {
      content: '\f095';
    }
    &#email p:before {
      content: '\f0e0';
    }
  }
}

// Accept Cards block
.accept-cards {
  margin: 0;
  padding: 0;
  text-align: right;
  li {
    display: inline-block;
    list-style-type: none;
    vertical-align: middle;
    padding-right: $gutter/2;
    &:last-child {
      padding-right: 0px;
    }
    &:before {
      font-family:fontAwesome;
      font-size: 1.75em;
      display: inline-block;
      vertical-align: middle;
    }
    &#visa:before {
      content: '\f1f0';
    }
    &#discover:before {
      content: '\f1f2';
    }
    &#amex:before {
      content: '\f1f3';
    }
    &#mastercard:before {
      content: '\f1f1';
    }
  }
}


//PRODUCT PAGES BLOCKS

//Photo gallery block
#block-productlinegallery-gallery {
  #flexslider-gallery-display{
    margin-top: 15px;
    margin-bottom: 5px;
    img {
      // width: 345px;
      border: 1px solid #abafb2;
    }
  }
 .flex-direction-nav a {
    line-height: 43px;
  }
}
#block-productlinegallery-gallery{
  margin-bottom: 0px;
  #flexslider-gallery-thumb{
    margin-bottom: 0px;
    .flex-viewport{
      height: auto !important;
      clear: both;
      ul > li{
        // margin-right: 5px;
        img {
          // border: 1px solid #abafb2;
        }
      }
      li:last-child{
        margin-right: 0px;
      }
    }
  }
}

// Learning Center block

//
// Educational resource block
//

#block-views-3e2637c6b89f28f44519818e6b2b75f8 {
  .view-related-educational-resources {
    .view-content {
      margin-top: 15px;
    }
  }
}

// Order now (product-kit-display-block)

#block-views-product-kit-display-block {
  background: $secondary-color;
  padding: $gutter;
  max-width: 100%;
  .block-title {
    @include kicker;
  }
  .view-mode-product_teaser {
    .node__title {
      font-size: 1.6em;
      line-height: 1.25em;
      font-weight: normal;
      color: $color-white;
      margin-bottom: $gutter;
      a {
        color: $color-white;
      }
    }
    .field-image-cache {
      text-align: center;
      margin: 0 auto $gutter;
    }
    .body {
      p, li {
        color: white;
      }
    }
    .selector {
      margin: $gutter 0 $gutter/2;
      width: 100% !important;
    }
    .add-to-cart {
      float: right;
      .form-item {
        margin-bottom: 0;
        margin-right: $gutter/3;
        label {
          display: none;
        }
      }
      .form-actions {
        input {
          margin-right: 0;
        }
      }
    }
    .display-price, .sell-price {
      color: $color-grey-light;
      padding-bottom: 0;
      padding-left: 0px;
      position: absolute;
      bottom: 3px;
      left: 0px;
      font-family: $oswald;
      font-weight: normal;
    }
  }
  @include breakpoint($tablet-portrait-min) {
    float: left;
    width: calc(50% - 20px);
    margin: 0 $gutter $gutter 0;
  }
}

//
// Related Products
//


#block-views-related-products-block {
  padding-top: $gutter/2;
  .block__title {
    margin-bottom: 12px;
  }
  .node-teaser {
    padding-bottom: 12px;
    z-index: 5;
    .node__title {
      font-size: 14px;
      text-align: center;
      color: white;
      padding: $gutter/2 $gutter;
      margin-bottom: 0;
      a {
        color: $color-white;
        font-weight: normal;
      }
    }
  }
  .field-image-cache {
    z-index: 1;
  }
  .flexslider-views-slideshow-main-frame-row {
    .field-image-cache {
      position: relative;
      margin: -1px 0px;
      border: 1px solid $primary-color;
      border-top: 0px;
      border-bottom: 0px;
      // &:after {
      //   content: '';
      //   @include absolute-position(0,0,0,0);
      //   opacity: 0.75;
      // }
    }
    .product-info {
      border: 1px solid $primary-color;
      border-top: 0px;
      border-bottom: 0px;
      padding: $gutter/2 $gutter/2 0;
      margin: -1px 0;
    }
    .add-to-cart {
      border: 1px solid $primary-color;
      border-top: 0px;
      padding: $gutter/2;
    }
    &:nth-child(3n) {
      // .field-image-cache:after {
      //   background-color: $tertiary-color;
      // }
      header {
        background-color: $tertiary-color;
      }
      .field-image-cache,
      .product-info,
      .add-to-cart {
        border-color: $tertiary-color;
      }
    }
    &:nth-child(3n+1) {
      // .field-image-cache:after {
      //   background-color: $primary-color;
      // }

      header {
        background-color: $primary-color;
      }
      .field-image-cache,
      .product-info,
      .add-to-cart {
        border-color: $primary-color;
      }
    }
    &:nth-child(3n+2) {
      // .field-image-cache:after {
      //   background-color: $secondary-color;
      // }
      header {
        background-color: $secondary-color;
      }
      .field-image-cache,
      .product-info,
      .add-to-cart {
        border-color: $secondary-color;
      }
    }
  }
  .product-info {
    float: none;
    text-align: right;
    width: 100%;
  }
  .product-info,
  .add-to-cart {
    padding: $gutter/2 0 0;
    .selector {
      width: calc(100% - 80px) !important;
      min-width: 100px !important;
      span {
        text-align: left;
      }
    }
  }
}

#block-multiproductlist-multi-list {
  width: 100%;
}