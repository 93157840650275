// Case Study

.resource-listing {
  .view-content {
    padding-top: $gutter*2;
  }
	.views-row {
		overflow: hidden;
		border-bottom: 1px solid $color-grey-light;
		padding: $gutter 0;
	}
	.views-row-last {
		border-bottom: none;
	}
	h3 {
		color: $secondary-color;
		padding:  0 0 $gutter/2;
		font-weight: normal;
    font-size: 1.6em;
    border-bottom: 1px solid $primary-color;
		&.list-title {
			color: inherit;
      border-bottom: 0px;
			text-shadow: none;
			padding: 0;
			font-size: 1.2em;
			margin: 0 0 5px 0;
		}
	}
	.field-edu-resource-author {
		margin: 0 0 5px 0;
	}
	.resource-body {
		float: left;
		width: calc(100% - 100px;);
    padding-right: $gutter/2;
	}
	.resource-links {
		float: left;
		width: 100px;
    padding-left: $gutter/2;
	}
	.links {
		text-align: center;
		display: block;
		float: left;
		font-size: 12px;
		width: 100%;
    padding-bottom: $gutter/2;
	}
	.icon {
		width: 44px;
		height: 36px;
		margin: auto;
		background-image:url("../images/sprite1x.png");
		background-repeat: no-repeat;
		display: block;
		&.slideshow {
			background-position: 2px bottom;
		}
		&.download {
			background-position: -72px bottom;
		}
		&.video {
			background-position: -131px bottom;
		}
	}
  @include breakpoint($tablet-portrait-max) {
    .resource-body {
      width: 100%;
      padding: 0 0 $gutter 0;
    }
    .resource-links {
      width: 100%;
      padding: 0;
      .links {
        width: auto;
        padding-right: $gutter;
      }
    }
  }
}

// Negate header margin on learning center landing page

body.page-node-453 .body {
	margin-top: -19px;
}

/* Learning Center Teaser found on product pages */
.node-educational-resource.node-teaser .node__title {
	margin-bottom: 0;
}


/* FAQ PAGES */

// List page
.view-faq.view-display-id-page_1 {
	.view-content .views-row {
		margin-bottom: 20px;
	}
	.attachment {
		ul {
			border-top: 1px dashed #e3e3e3;
			padding: 20px 20px 20px 40px;
			border-bottom: 1px dashed #e3e3e3;
		}
		.views-row {
			margin-bottom: 0;
		}
	}
}

// Flexslider in colorbox overlay
.slider-overlay {
	.flexslider {
		margin: 0;
		.lcenter-slideshow-img {
			float: left;
			margin-right: 25px;
		}
		.slide-description {
		float: left;
		height: 276px;
		width: 350px;
		overflow-y: scroll;
		}
	}
	.slides > li {
		position: relative;
	}
	.slides img {
		width: auto;
	}
}

#cboxClose {
	text-transform: uppercase;
	position: absolute;
	color: #bcbdc0;
	top: 2px;
	right: 13px;
	background: url("../images/sprite1x.png") no-repeat right -257px;
	width: auto;
	padding-right: 29px;
	height: 25px;
	text-indent: 0;
	font-size: 12px;
&:hover, &:active {
	color: $color-orange;
	background-position: right -279px;
}
&:focus {
	box-shadow: none;
}
}
#cboxLoadedContent {
	margin-bottom: 14px;
}

#slider {
  @include modal-styling;
	.flex-direction-nav a {
		font-size: 19px;
		border: 2px solid $color-orange;
		height: 24px;
		width: 104px;
		text-align: center;
		line-height: 24px;
		margin: 0;
		top: 305px;
		z-index: 30;
		opacity: .5;
		color: $color-orange;
		text-indent: 0;
		left: 520px;
		&.flex-next:before {
			content: none;
		}
		&.flex-prev {
  		display: none;
  	}
	}
	.flex-direction-nav a {
		&:hover {
			opacity: 1;
			right: auto;
		}
	}
}

.slide-index {
	position: absolute;
	top: 0;
	left: 0;
	line-height: 1;
	padding: 3px 8px 4px 6px;
	color: #ffffff;
	background-color: $body-font-color;
	display: block;
	font-weight: bold;
	font-weight: 700;
}

.flex-active-slide .slide-index {
	background: $color-orange;
}

#slide-nav {
  padding: 0 24px;
  .slides > li {
     margin-right: 12px;
  }
  &:hover {
    .flex-direction-nav .flex-disabled {
       display: block;
       opacity: .25 !important;
       filter: alpha(opacity=25);
    }
  }
  .flex-direction-nav a {
    width: 18px;
    height: 72px;
    margin: -20px 0 0;
    background-position: bottom right;
    top: 30px;
    display: block;
    opacity: 1;
    filter: alpha(opacity=100);
    background-image: url("../images/sprite1x.png");
    background-repeat: no-repeat;
    &.flex-next {
       right: 2px;
       background-position: -20px -89px;
       @include hide-text;
   }
   &.flex-prev {
      left: 2px;
      background-position: left -89px;
      @include hide-text;
   }
   &.flex-disabled {
      display: block;
      opacity: .25 !important;
      filter: alpha(opacity=25);
      // pointer-events: none;
   }
 }
 .slides img {
    margin: 0 auto;
 }
}

// .resource-body {
// 	display: none;
// }

#colorbox {
	.colorbox-video {
		display: block;
	}
}

html.js .colorbox-video {
	display: none;
}

#cboxContent {
	padding-top: 28px;
}

