//
// Flexslider
//


//Default Slider Controls
.flex-direction-nav {
  li {
    height: 100%;
    width: $gutter;
  }
  a {
    width: $gutter;
    top: $gutter;
    display: block !important;
    opacity: 1;
    filter: alpha(opacity = 100);
    height: 100%;
    color: transparent;
    &:before {
      content: '';
      font-family: fontAwesome !important;
      font-size: 2.5em !important;
      color: $primary-color;
      display: block;
      width: $gutter;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%,-50%);
    }
    &:hover:before {
      color: $secondary-color;
    }

    &.flex-prev {
      left: 0;
      &:before {
        content: '\f104';
      }
    }

    &.flex-next {
      right: 0;

      &:before {
        content: '\f105';
      }
    }

    &.flex-disabled {
      display: block;
      opacity: .25 !important;
      filter: alpha(opacity = 25);
      pointer-events: none;
    }
  }
}

.flexslider:hover .flex-next {
  right: 0;
}

.flexslider:hover .flex-prev {
  left: 0;
}

#flexslider_views_slideshow_main_Carousel-block_1 {
  .flex-direction-nav {



    a {

      position: absolute;
      // bottom: 0;
      top: 50%;
      transform: translate(0,-50%);
      // width: 100%;
      //height: $gutter*2;

      width: $gutter*2;
      height: $gutter*2;
      background: $primary-color;
      text-align: center;
      &:before {
        color: white;
        text-align: center;
        font-size:1.75em !important;
        position: relative;
        top:10px;
        transform: none;
        position: relative;
        left: auto;
        width: 100%;
      }
      &:hover {
        background: $tertiary-color;
      }
    }
  }

  .flex-control-nav {
    width: 80%;
    left: 10%;
    z-index: 999;
  }

  a {

  }

  a.flex-prev {
    box-shadow: 1px 1px 3px $secondary-color;
    &:before {
      left:-2px;
    }
  }

  a.flex-next {
    box-shadow: -1px 1px 3px $secondary-color;
    &:before {
      right: -2px;
    }
  }

  img {
    max-height:485px;
  }

  .flexslider {
    margin: 0;
    border-radius: 0px;

    .flex-next,
    .flex-prev {
      opacity: 1;
      display: block;

      //position: absolute;
      //top: 337px;

    }

    .flex-control-paging {
      bottom: 0px;
      // top: 325px;

      li {
        margin: 0px 2px;
      }

      li a.flex-active {
        background-image: none;
        background-color: $primary-color;

        &:hover {
          background-color: $primary-color;
        }
      }

      li a {
        margin-top: 3px;
        background-image: none;
        background: none;
        background-color: $color-grey-light;

        @include border-radius(0px);

        border: 1px solid $primary-color;
        width: 12px;
        height: 12px;
        box-shadow: none;

        &:hover {
          background-color: white;
        }
      }
    }
    @include breakpoint($desktop-min) {
      a.flex-next {
        right:-$gutter;
      }
      a.flex-prev {
        left: -$gutter;
      }
    }
    @include breakpoint($tablet-portrait-max) {
      @include break-container;
    }
  }
}

// Product Line Photo Gallery thumbnails

#flexslider-gallery-thumb,
#flexslider-video-gallery-thumb {
  .slides > li {
    cursor: pointer;
    transition: all 0.25s ease;
    opacity: 0.5;
    &.flex-active-slide, &:hover {
      cursor: pointer;
      border: 1px solid $primary-color;
      opacity: 1;
    }
    img {
      width: auto;
    }
  }
  .flex-viewport {
    width: calc(100% - 40px);
    margin: 0 auto;
  }
 .flex-direction-nav a {
    &:before {
      font-size: 1.5em !important;
    }
  }
}

//Related Products Slider

#block-views-related-products-block, #slide-nav {

  .flexslider .slides > li {
    // margin-right: $gutter;
    margin-right: 20px;
  }

  .flexslider {
    // padding: $gutter*1.5;
    padding: 0px 24px;

    &:hover {
      .flex-direction-nav .flex-disabled {
        display: block;
        opacity: .25 !important;
        filter: alpha(opacity = 25);
      }
    }

    .flex-direction-nav a {
      display: block;
      opacity: 1;
      filter: alpha(opacity = 100);
      // background-image: url("../images/sprite1x.png");
      // background-repeat: no-repeat;

      &.flex-next {
        right: 0;

        // @include hide-text;
      }

      &.flex-prev {
        left: 0;

        // @include hide-text;
      }

      &.flex-disabled {
        display: block;
        opacity: .25 !important;
        filter: alpha(opacity = 25);
        pointer-events: none;
      }
    }

    .slides img {
      margin: 0 auto;
    }
  }
}
