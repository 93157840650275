p {
  color: $color-dark-blue;
  font-family: $sans-serif;
  font-size: 1em;
}

h1, h2, h3 {
  font-family: $oswald;
  text-transform: uppercase;
  font-weight: normal;
}

h1 {
  size: 2em;
}

h2 {
  size: 1.6em;
}

h3 {
  size: 1.2em;
}

#page-title, #section-title {
  // color: $color-orange;
  font-size: 2em;
  margin-bottom: 0;
  text-transform: uppercase;
  // margin-right: 100px;
  line-height: 1.4em;
}

.wys-block-text img {
  padding-right: $gutter;
}