
.page-shows-and-seminars{
   .gd-form{
      table{
         margin-top: 0;
         th{
            a{
               text-decoration: none;
               img{
                  border:none;
                  padding-left: 5px;
                  padding-bottom: 3px;

               }
            }
         }
      }
   }
}
