
//
// Search
//

// #footer #block-search-form {
//    float: right;
// }

.block-search {
  float: right;

   #search-block-form {
      position: relative;
  }

  .form-item-search-block-form {
     margin: 0;
  }

  input[type="text"] {
     height: 21px;
     font-size: 11px;
     text-transform: uppercase;
     margin-right: 0;
     color: $color-grey-dark;
     vertical-align: top;
     padding-right: 22px;

     &:focus {
        box-shadow: none;
        border: 1px solid $color-orange;
    }
  }

  input[type="submit"] {
     padding: 0;
     border: none;
     background-color: transparent;
     // background-image: url("../images/sprite1x.png");
     // background-repeat: no-repeat;
     // background-position: right -59px;
     text-indent: -999999em;
     width: 21px;
     height: 21px;
     position: absolute;
     top: 0px;
     right: 0px;
     margin-right: 0;
     overflow: hidden;
     box-shadow: none;
  }

  .search-toggle,
  #edit-actions {
     padding: 0;
     border: none;
     text-indent: -999999em;
     width: 16px;
     height: 16px;
     position: absolute;
     top: 4px;
     right: 1px;
     margin-right: 0;
     overflow: hidden;
     box-shadow: none;
     &:before {
      content: '\f002';
      font-family:fontAwesome;
      color: $color-dark-blue;
      display: block;
      text-indent: 0;
     }
  }

  .search-toggle {
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -webkit-touch-callout: none;
    -ms-touch-action: manipulation;
    touch-action: manipulation;
  }
  @include breakpoint($tablet-small-min) {
    #search-block-form {
      padding-top: 4px;
    }
  }
  @include breakpoint($tablet-small-max) { 
    width: 100%;
    margin: 0 auto;
    height: 0px;
    z-index: 1;
    #search-block-form {
      width: 100%;
      background: $color-grey-light;
      padding: 0px !important;
      visibility: visible !important;
      input {
        font-size: 16px;
        height: $gutter*1.5;
      }
    }
    .search-toggle {
      display: none;
    }
    #edit-actions:before {
      display: none;
    }
    &.is-active-search {
      height: auto;
      #search-block-form {
        padding: $gutter/2 $gutter !important;
      }
    }
  }
}

@media
only screen and (-webkit-min-device-pixel-ratio: 2),
only screen and (   min--moz-device-pixel-ratio: 2),
only screen and (     -o-min-device-pixel-ratio: 2/1),
only screen and (        min-device-pixel-ratio: 2),
only screen and (                min-resolution: 192dpi),
only screen and (                min-resolution: 2dppx) {

  .block-search input[type="submit"] {
      background-image: url("../images/sprite2x.png");
      background-size: 240px 300px;
      background-color: transparent;
  }

  .block-search .search-toggle {
    &:before {
      content: '\f002';
      font-family:fontAwesome;
      color: $color-dark-blue;
      text-indent: 0;
    }
  }

  html.lt-ie9 .block-search input[type="submit"],
  html.lt-ie9 .block-search .search-toggle {
     background-image: url("../images/sprite1x.png");
  }

} /* END RETINA MEDIA QUERY */


////////////////////////
// Behavior / Animation
////////////////////////

.block-search,
.search-toggle {
  display: inline-block;
}

.search-toggle {
  cursor: pointer;
}

.block-search {
  .contextual-links-wrapper {
    display: none !important;
  }

  input[type="text"] {
    visibility: hidden; // Ensure clicks don't happen on input. Only on toggle icon.
    width: 100%;
    background-color: transparent;
    border: 1px solid transparent;
    transition: background-color 0.15s ease-out, border-color 0.15s ease-out;
  }

  input[type="submit"] {
    visibility: hidden;
  }

  form {
    width: 27px;
    transition: width 0.15s ease-out;
  }

  &.is-active-search {
    .search-toggle {
      visibility: hidden;
    }

    input[type="text"] {
      visibility: visible;
      background-color: #ffffff;
      border: 1px solid #ccced0;
      box-shadow: $color-grey-light 2px 2px 2px inset;

      &:focus {
        box-shadow: none;
        border: 1px solid $color-orange;
      }
    }

    input[type="submit"] {
      visibility: visible;
    }

    form {
      width: 195px;
    }
  }
}


