
select.rolodex {
	width: 100%;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	vertical-align: middle;
}

.rolodex-list {
	position: relative;
	margin: 0;
	padding: 0;
	list-style: none;
	overflow: hidden;
	.rolodex-item {
		max-height: 0;
		position: absolute;
		top: 0;
		left: 100%;
		transition: opacity 0.2s linear 0s, transform 0.2s linear 0s;
		transform: scale(0);
		opacity: 0;
		&.selected {
			transition: opacity 0.4s ease-in-out 0s, transform 0.4s cubic-bezier(.6,-.28,.735,.045) 0s, height 2s ease-in-out 2s;
			max-height: 100em;
			position: static;
			transform: scale(1);
			opacity: 1;
		}
	}
}
