#footer-wrapper {
  background-color: $secondary-color;
  margin-top: $gutter*3;
  clear: both;
  a, p, li {
    color: white;
  }
  a:hover {
    color: $primary-color;
  }
  h2 {
    color: $color-grey-light;
  }
  .social-media-icons {
    margin-top: $gutter;
  }
  #footer-content-wrapper {
    z-index: 20;
    position: relative;
    margin: 0 auto;
    background-color: $secondary-color;
    padding: $gutter*2 $gutter;
    @include clearfix;
    color: white;
    #footer-left-wrapper,
    #footer-right-wrapper {
      a {
        color: white;
        &:hover {
          color: $primary-color;
        }
      }
    }
    #footer-right-wrapper {
      padding-top: $gutter*2;
      .region-footer-right {
        @include clearfix;
      }
    }
  }
  #footer-bottom-wrapper {
    clear: both;
    @include clearfix;
    padding: $gutter*1.5 0;
    border-top: 1px solid $tertiary-color;
    li {
      color: $color-grey-light;
    }
  }
  @include breakpoint($tablet-portrait-min) {
    #footer-left-wrapper,
    #footer-right-wrapper {
      float: left;
      width: 50%; 
      padding-top: 0 !important;
    }
  }
  @include breakpoint($tablet-small-min) {
    #footer-right-wrapper {
      .block {
        float: left;
        width: 50%;
      }
    }
    #footer-bottom-wrapper {
      #block-block-30,
      #block-block-31 {
        float: left;
        width: 50%;
      }
    }
  }
  @include breakpoint($tablet-small-max) {
    #footer-bottom-wrapper {
      #block-block-30,
      #block-block-31,
      ul {
        text-align: center;
      }
      ul {
        padding-top: $gutter/2;
      }
    }
  }
}

/**
 * Footer font color
 */
.block-garrisonblocks-made-in-usa,
.block-simple-subscription-subscribe .simple_subscription_header,
.block-garrisonblocks-footer-logo-and-address,
#block-menu-menu-footer-menu {
  color: white;
}

/**
 *  Footer region layout
 */

#footer-left-wrapper {
  .region-footer-left {
    @include clearfix;
  }
}

/**
 * Footer Menu
 */
footer {
  .block {
    margin-bottom: 0px;
  }
}

.block-simple-subscription-subscribe .simple_subscription_header,
#block-menu-menu-footer-menu {
  font-family: $source-sans-pro;
  font-weight: 600;
  font-size: 12px;
  z-index: 100;

  a, a:link, a:visited {
    color: $color-grey-dark;
  }

  a:hover, a:active {
    color: $color-grey-dark;
  }

  ul.menu {
    padding: 0px;
    margin: 0px;
    line-height: 1.8em;
    li {
      list-style-type: none;
    }
  }
}

#block-menu-menu-footer-menu {
  float: left;
}

/**
 * USA!!
 */
.block-garrisonblocks-made-in-usa {
  font-size: 10px;
  clear: both;

  img {
    display: inline-block;
    margin-right: 0.5em;
  }

  p {
    margin: 0;
    padding: 3px 1em 0 0;
    display: inline-block;
    line-height: 1;
    vertical-align: middle;
  }

  a, a:link, a:visited {
    color: $color-grey-dark;
  }

  a:hover, a:active {
    color: $color-grey-dark;
  }
}

.terms-links {
  a {
    margin-right: $inline-field-gutter;
  }
}

/**
 * Address and logo
 */
.block-garrisonblocks-footer-logo-and-address {
  clear: both;

  img {
    width: 100px;
  }

  p {
    font-family: $source-sans-pro;
    font-size: 11px;
    margin: 0;
    padding-top: 13px;
    line-height: 1.5em;
  }

  table {
    float: right;
    clear: both;
    margin: 0;
  }
}

/**
 * Social Links
 */
#block-garrisonblocks-social-links {
  img {
    width: 21px;
    height: 20px;
    padding-left: 5px;
  }

  // float: right;
}

/**
 * Subscribe to newsletter
 */
#footer-content-wrapper .block-webform {
  margin-bottom: 0;

  // h2.block-title {
  //   display: none;
  // }

  .form-item {
    float: left;
    margin: 0;

    #edit-submitted-email {
      width: 230px;
      height: 22px;
      font-size: 12px;
      vertical-align: top;
    }
  }

  input[type="submit"] {
    @include wireframe-btn;

    font-size: 12px;
    margin-left: 4px;
    margin-top: 19px;
    height: 22px;
    vertical-align: top;
    margin-right: 4px;
    color: $color-grey-dark;
  }

  label {
    line-height: 1.8em;
    font-family: $source-sans-pro;
    font-weight: 600;
    font-size: 12px;
    z-index: 100;

    .form-required {
      display: none;
    }
  }
}

.block-garrison-crm-newsletter-signup {
  // float: left;
  // margin-right: 1em;
}

.block-garrisonblocks-social-links {
  float: left;
}