
.node-clinicians {
  .field-clinician-location {
      font-weight: bold;
   }
  &.node-teaser {
  	margin-bottom: $gutter*1.5;
  	overflow: hidden;
  	.clinician-link, .field-clinician-website {
  		display: inline-block;
  	}
  	.clinician-link {
  		margin-right: .25em;
  	}
  	.clinician-info {
  		margin-top: 14px;
  		h2 {
  			font-size: 14px;
  		}
  	}
  	.field-clinician-image {
  		img {
        width: 100%;
        height: auto;
      }
  	}
  	.field-clinician-location,
  	.field-clinician-testimonial {
  		margin-bottom: 5px;
  	}
  }
  @include breakpoint($tablet-small-min) {
    .field-clinician-image {
      float: left;
      width: 33.3333%;
      padding-right: $gutter/2;
    }
    .clinician-info {
      float: left;
      width: 66.6666%;
      padding-left: $gutter/2;
    }
    &.view-mode-full {
      .field-clinician-location,
      .field-clinician-testimonial,
      .links {
        float: right;
        width: 66.6666%;
        padding-left: $gutter/2;
      }
    }
  }
  @include breakpoint($tablet-small-max) {
    .field-clinician-image {
      width: 100%;
      padding-bottom: $gutter;
    }
  }
}
