
.node-customer-service-representative.node-teaser {
	margin-bottom: 28px;
	overflow: hidden;
	.field-customer-rep-email, .field-customer-rep-phone-number {
		display: inline-block;
	}
	.field-customer-rep-phone-number {
		margin-right: .25em;
	}
	.customer_rep-info {
		// margin-left: 239px;
		margin-top: 14px;
		h2 {
			font-size: 14px;
		}
	}
	.field-customer-rep-image {
		float: left;
	}
	.field-customer-rep-phone-number,
	.field-customer-rep-bio {
		margin-bottom: 5px;
	}
}

.node-customer-service-representative{
   .field-customer-rep-image{
      float: left;
      margin: 0px 10px 10px 0px;
   }
}
