// Product Listing

.product-subsection-list {
  @include clearfix;

  li {
    position: relative;
    float: left;

    .views-field-field-product-line-second-image {
      .field-content {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;

        a {
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
          font-size: 0;
          z-index: 4;

          img:before {
            content: '';
            position: absolute;
            width: 100%;
            height: 100%;
            transition: all 0.25s ease;
            z-index: 2;
          }
        }

        h3 {
          position: absolute;
          width: 100%;
          top: 50%;
          left: 0;
          transform: translate(0, -50%);
          text-align: center;
          font-family: $source-sans-pro;
          margin: 0;
          z-index: 3;
          color: white;
          font-weight: bold;
        }

        img {
          width: 100%;
          height: auto;
          // min-height: 100px;
          z-index: 1;
        }

        .overlay {
          &:before {
            content: '';
            @include absolute-position(0, 0, 0, 0);
            transition: all 0.25s ease;
            z-index: 2;
            opacity: 0.75;
          }
        }
      }
    }

    &:hover {
      .views-field-field-product-line-second-image .overlay:before {
        opacity: 1;
      }
    }
  }

  li:nth-child(4n+2) {

    .views-field-field-product-line-second-image .overlay:before,
    .views-field-field-product-line-second-image img {
      background-color: $primary-color;
    }
  }

  li:nth-child(4n+1) {

    .views-field-field-product-line-second-image .overlay:before,
    .views-field-field-product-line-second-image img {
      background-color: $secondary-color;
    }
  }

  li:nth-child(4n) {

    .views-field-field-product-line-second-image .overlay:before,
    .views-field-field-product-line-second-image img {
      background-color: $color-grey;
    }
  }

  li:nth-child(4n+3) {

    .views-field-field-product-line-second-image .overlay:before,
    .views-field-field-product-line-second-image img {
      background-color: $body-font-color;
    }
  }

  @include breakpoint($tablet-small-min) {
    li {
      margin: $gutter $gutter 0 0;
      width: calc(100% / 3 - 14px);
    }

    li:nth-child(3n) {
      margin-right: 0px;
    }

    li:nth-child(3n+1) {
      clear: both;
    }
  }

  @include breakpoint($tablet-small-max) {
    li {
      width: calc(50% - 10px);
      margin-bottom: $gutter;
    }

    li:nth-child(even) {
      margin-left: $gutter/2;
      margin-right: 0;
    }

    li:nth-child(odd) {
      margin-right: $gutter/2;
    }
  }
}

//
// Product Section List Page
//

.product-section-list {
  li {
    float: left;
    border: 1px solid $primary-color;
    padding: 0;
  }

  .views-field-name a,
  .views-field-name-field a {
    text-align: center;
    display: block;
    padding: $gutter/2 $gutter;
    color: white;
    text-transform: uppercase;
    font-weight: normal;
    font-size: 1.1em;
    font-family: $oswald;
  }

  .views-field-entity-id {
    img {
      display: block;
      width: 100%;
      height: auto;
    }
  }

  li:nth-child(4n+2) {
    border-color: $primary-color;

    .views-field-name a,
    .views-field-name-field a {
      background-color: $primary-color;
    }
  }

  li:nth-child(4n+1) {
    border-color: $secondary-color;

    .views-field-name a,
    .views-field-name-field a {
      background-color: $secondary-color;
    }
  }

  li:nth-child(4n) {
    border-color: $color-grey;

    .views-field-name a,
    .views-field-name-field a {
      background-color: $color-grey;
    }
  }

  li:nth-child(4n+3) {
    border-color: $body-font-color;

    .views-field-name a,
    .views-field-name-field a {
      background-color: $body-font-color;
    }
  }

  @include breakpoint($tablet-small-min) {
    li {
      margin: $gutter $gutter 0 0;
      width: calc(100% / 3 - 14px);
    }

    li:nth-child(3n) {
      margin-right: 0px;
    }

    li:nth-child(3n+1) {
      clear: both;
    }
  }

  @include breakpoint($tablet-small-max) {
    li {
      width: calc(50% - 10px);
      margin-bottom: $gutter;
    }

    li:nth-child(even) {
      margin-left: $gutter/2;
      margin-right: 0;
    }

    li:nth-child(odd) {
      margin-right: $gutter/2;
    }
  }
}


// Distributor links
.field-distributor-us-link,
.field-distributor-canada-link,
.field-distributor-europe-link,
.field-distributor-row-link {
  margin: 0 0 $gutter;
  float: right;

  a:not(.contextual-links-trigger) {
    font-size: 1em;
    padding: $gutter/2 $gutter;
    // height: 18px;
    line-height: 18px;
    display: inline-block;
    text-transform: uppercase;
    background: $primary-color;
    color: $color-white;
    font-family: $oswald;
    transition: all 0.25s ease;

    &:hover,
    &:active,
    &:focus {
      background-color: darken($primary-color, 10%);
      color: #ffffff !important;
      text-decoration: none;
    }
  }
}
