ul.tabs-primary {
  padding: 0;
  margin-bottom: 1em;
  border-bottom: 1px solid $primary-color;
  overflow: hidden;
  li.tabs-primary__tab {
    list-style: none;
    display: inline-block;
    margin: 0 $gutter/4 0 0;
    a {
      border: 0px;
      display: block;
      padding: $gutter/4 $gutter/2;
      background: $color-grey-light;
      border-radius: 4px 4px 0 0;
      color:$secondary-color;
      text-transform: uppercase;
      font-weight: bold;
      letter-spacing: 1px;
      font-size: 0.75em;
      transition: all 0.25s ease;
      &.is-active {
        background: $primary-color;
        color: white;
      }
      &:hover {
        background: $tertiary-color;
        color: white;
      }
    }
  }
}