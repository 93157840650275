/**
 * @file
 * SMACSS Modules
 *
 * Adds modular sets of styles.
 *
 * Additional useful selectors can be found in Zen's online documentation.
 * https://drupal.org/node/1707736
 *
 * Custom for Garrison Dental
 *
 */


/**
 * Carosel
 */

/**
 * uc_catalog
 */
.view-uc-catalog{
   .view-header > p{
      color: $color-grey-mid;
      font-weight: bold;
      font-size: 17px;
      padding-bottom: 8px;
   }
}
/**
 * News View
 */
.view-id-news {
  .view-header {
    text-align: right;
  }
}

