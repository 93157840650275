.collapsible.form-wrapper {
  padding: $gutter;
  margin: 0 0 $gutter;
  .fieldset-legend {
    padding: 0 10px;
  }
  .fieldset-description {
    padding: 0 0 $gutter/2;
  }
}


#ups-validation-modal {
    .btn-submit.continue {
    line-height: auto !important;
    padding: $gutter/4 $gutter/2 !important;
  }
}

// Checkout forms

#uc-cart-checkout-form {
  input[type="date"],
  input[type="datetime"],
  input[type="datetime-local"],
  input[type="month"],
  input[type="password"],
  input[type="search"],
  input[type="tel"],
  input[type="time"],
  input[type="week"],
  input[type="text"],
  .selector {
    width: calc(100% - 15em) !important;
    margin-left: 0px !important;
    margin-right: 0px !important;
  }
  .form-item-panes-billing-copy-address {
    padding-bottom: $gutter/2;
  }
  .payment-details-credit label {
    width: 15em;
    text-align: right;
    padding-right: $gutter/4;

  }
  .form-item-panes-payment-details-cc-exp-year {
    display: inline-block;
    vertical-align: bottom;
    width: calc(25% - 5px);
    padding-left: $gutter/4;
    .selector {
      width: 100% !important;
    }
  }

  .form-item-panes-payment-details-cc-exp-month {
    display: inline-block;
    vertical-align: bottom;
    width: 75%;
    .selector {
      width: calc(100% - 15em) !important;
    }
  }
  @include breakpoint($tablet-small-max) {
    input[type="date"],
    input[type="datetime"],
    input[type="datetime-local"],
    input[type="month"],
    input[type="password"],
    input[type="search"],
    input[type="tel"],
    input[type="time"],
    input[type="week"],
    input[type="text"],
    .selector {
      width: 100% !important;
    }
    label,
    .payment-details-credit label {
      text-align: left;
      width: 100%;
      padding: $gutter/4 0 0;
    }
    .form-item-panes-payment-details-cc-exp-year {
      width: calc(30% - 5px);
      padding-left: 0;
    }

    .form-item-panes-payment-details-cc-exp-month {
      width: 70%;
      .selector {
        width: 100% !important;
      }
    }
  }
}

// Email popup form
// .cboxIframe {
//   .clickdform.mainDiv {
//     // padding-left: 0px !important;
//     // padding-top: 0px !important;
//   }
//   input[type="text"] {
//     font-family: $sans-serif !important;
//     padding: $gutter/4 $gutter/2 !important;
//     border: 1px solid $tertiary-color !important;
//     &:focus {
//       border: 1px solid $primary-color !important;
//     }
//     span {
//       font-family: $sans-serif !important;
//     }
//   }
//   input[type="button"] {
//     font-family: $oswald !important;
//     color: white !important;
//     background-image: none !important;
//     background-color: $primary-color !important;
//     padding: $gutter/4 $gutter/2;
//     &:hover {
//       background-color: darken($primary-color, 10%);
//     }
//   }
// }