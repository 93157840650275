// _home.scss

.front {
  #navigation {
    background-color: $secondary-color;
    background-image: url(../images/square-bg.png);
    background-position: center -6px;
    background-repeat: no-repeat;
    margin-bottom: $gutter*8;
    background-size: 1670px auto;
    .menu-block-wrapper {
      a, a:link, a:visited {
        color: white !important;
        &:hover {
          color: $primary-color !important;
        }
      }
    }
  }
  .main-logo {
    #dark-logo {
      display: none;
    }
    #light-logo {
      display: block;
    }
  }
  .block-views-carousel-block-1 {
    clear: both;
  }
  #block-menu-menu-products {
    margin-bottom: -$gutter*5;
  }
  @media (max-width: 839px) and (min-width: 600px) {
    #tablet-menu-toggle:before {
      background: $primary-color !important;
    }
    #navigation {
      .menu-block-wrapper {
        &.show-main-nav {
          ul.menu {
            background: $primary-color !important;
          }
          a, a:link {
            &:hover {
              color: $secondary-color !important;
            }
          }
        }
      }
    }
  }
  @include breakpoint($tablet-portrait-max) {
    #block-menu-menu-products {
      @include break-container;
    }
  }
}