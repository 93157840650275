#page, .region-bottom {
  max-width: 100%;
}

.region-header,
#main,
#region-bottom-wrapper > .region-bottom,
#footer-content-wrapper,
#footer-bottom {
  @include container;
}

#navigation {
  @include break-container;
  .region-navigation {
    @include container;
    @include clearfix;
  }
}


//Desktop
@include breakpoint($tablet-portrait-min) {
  #sidebar-first,
  #sidebar-second {
    width: 25%;
    float: left;
  }
  #sidebar-first {
    padding-right: $gutter/2;
  }
  #sidebar-second {
    padding-left: $gutter/2;
  }
  #content {
    float: left;
    margin: 0 !important;
  }
  .two-sidebars {
    #content {
      width:50%;
      padding-left: $gutter/2;
      padding-right: $gutter/2;
    }
  }
  .one-sidebar {
    &.sidebar-first {
      #content {
        padding-left: $gutter/2;
        width: 75%;
      }
    }
    &.sidebar-second {
      #content {
        padding-right: $gutter/2;
        width: 75%;
      }
    }
  }
  .no-sidebars {
    #content {
      width: 100%;
    }
  }
}
@include breakpoint($tablet-portrait-max) {
  #sidebar-first,
  #sidebar-second {
    clear: both;
  }  
  #sidebar-second {
    padding-top: $gutter*1.5;
    border-top: 1px solid $color-grey-light;
    margin-top: $gutter*1.5;
  }
}