
.webform-client-form label {
	margin-right: 8px;
	color: $body-font-color ;
	font-weight: normal;
	font-size: 14px;
}


// Student page page-node-427
#block-webform-client-block-425,
#block-webform-client-block-426 {
	.form-item {
		float: left;
	}
	.form-actions {
		clear: both;
		text-align: right;
		input {
			margin-right: 7px;
		}
	}
}

.block-quicktabs {
	overflow: hidden;
	.ui-tabs .ui-tabs-panel {
		padding: 12px 0;
	}
}


// CURRENT STUDENT FORM

#webform-component-offers-stud-first-name {
	width: 357px;
	margin-right: 12px;
	input {
		width: 270px;
	}
}
#webform-component-offers-stud-last-name {
	width: 346px;
	margin-right: 0;
	input {
		width: 259px;
	}
}

#webform-component-offers-stud-email {
	width: 360px;
	margin-right: 12px;
	input {
		width: 303px;
	}
}
#webform-component-offers-stud-phone {
	width: 344px;
	input {
		width: 290px;
	}
}
#webform-component-offers-stud-address {
	width: 714px;
	input {
		width: 652px;
	}
}
#webform-component-offers-stud-city {
	width: 390px;
	margin-right: 12px;
	input {
		width: 352px;
	}
}
#webform-component-state {
	width: 166px;
	margin-right: 12px;
	select {
		width: 112px;
	}
}
#webform-component-offers-stud-zip {
	width: 134px;
	input {
		width: $small-width;
	}
}

#webform-component-name-of-school {
	margin-right: 12px;
	input {
		width: 280px;
	}
}

#webform-component-school-use-products {
	input {
		width: 292px;
	}
}

#webform-component-lunch--learn-contact {
	margin-right: 12px;
	input {
		width: 218px;
	}
}

#webform-component-adsa-contact-field {
	input {
		width: 218px;
	}
}

#webform-component-request-a-sample {
	width: 50%;
	.form-type-radio {
		float: right;
	}
}

// GRAD Form

#webform-component-offers-grad-first-name {
	width: 333px;
	margin-right: 12px;
	input {
		width: 252px;
	}
}

#webform-component-offers-grad-last-name {
	width: 371px;
	margin-right: 0;
	input {
		width: 294px;
	}
}

#webform-component-offers-grad-practice {
	width: 484px;
	margin-right: 12px;
	input {
		width: 340px;
	}
}

#webform-component-offers-grad-year {
	width: 220px;
	margin-right: 0;
	select {
		width: 92px;
	}
}

#webform-component-offers-grad-bus-email {
	width: 360px;
	margin-right: 12px;
	input {
		width: 258px;
	}
}

#webform-component-offers-grad-bus-phone {
	width: 344px;
	input {
		width: 236px;
	}
}

#webform-component-offers-grad-bus-address {
	width: 716px;
	input {
		width: 598px;
	}
}

#webform-component-offers-grad-city {
	width: 376px;
	margin-right: 12px;
	input {
		width: 338px;
	}
}

#webform-component-offers-grad-state {
	width: 178px;
	margin-right: 12px;
	select {
		width: 112px;
	}
}

#webform-component-offers-grad-zip {
	width: 134px;
	input {
		width: $small-width;
	}
}


.page-node-427 {
	.ui-tabs-nav li {
		margin-right: 0;
		a {
			padding: 4px 16px;
			width: 315px;
			margin-right: 8px;
		}
		&:last-child {
				margin-right: 0;
			}
	}

}

/* Contact Us Webform */

#block-webform-client-block-618 {
	margin-top: 20px;
	padding: 20px;
	border: 1px dashed #e3e3e3;
	#webform-component-contact-name ,
	#webform-component-contact-email {
		margin-right: 5px;
		display: inline-block;
	}
	#webform-component-contact-phone {
		display: inline-block;
	}
}


// GRAD Form

#webform-component-offers-grad-first-name {
	width: 333px;
	margin-right: 12px;
	input {
		width: 252px;
	}
}

#webform-component-offers-grad-last-name {
	width: 371px;
	margin-right: 0;
	input {
		width: 294px;
	}
}

#webform-component-offers-grad-practice {
	width: 484px;
	margin-right: 12px;
	input {
		width: 340px;
	}
}


#webform-component-offers-grad-year {
	width: 220px;
	margin-right: 0;
	select {
		width: 92px;
	}
}

#webform-component-offers-grad-bus-email {
	width: 360px;
	margin-right: 12px;
	input {
		width: 258px;
	}
}

#webform-component-offers-grad-bus-phone {
	width: 344px;
	input {
		width: 236px;
	}
}

#webform-component-offers-grad-bus-address {
	width: 716px;
	input {
		width: 598px;
	}
}

#webform-component-offers-grad-city {
	width: 376px;
	margin-right: 12px;
	input {
		width: 338px;
	}
}

#webform-component-offers-grad-state {
	width: 178px;
	margin-right: 12px;
	select {
		width: 112px;
	}
}

#webform-component-offers-grad-zip {
	width: 134px;
	input {
		width: $small-width;
	}
}


.page-node-427 {
	.ui-tabs-nav li {
		margin-right: 0;
		a {
			padding: 4px 16px;
			width: 315px;
			margin-right: 8px;
		}
		&:last-child {
				margin-right: 0;
			}
	}

}

/* Contact Us Webform */

#block-webform-client-block-618 {
	span.form-required {
		display: none;
	}
	margin-top: 20px;
	padding: 20px;
	border: 1px dashed #e3e3e3;
	#webform-component-contact-name ,
	#webform-component-contact-email {
		margin-right: 5px;
		display: inline-block;
		float: left;
	}
	#webform-component-contact-phone {
		display: inline-block;
		float: left;
		margin-right: 5px;
	}
}


/* Dealer Promo */

#webform-client-form-781 {
	span.form-required {
		display: none;
	}
	#webform-component-first-name,
	#webform-component-dealer-name,
	#webform-component-city,
	#webform-component-state,
	#webform-component-zip-code {
		margin-right: 5px;
		display: inline-block;
		float: left;
	 }
	#webform-component-state {
		width: 120px;
	}
	#edit-submitted-street-address-1-work-or-other, #edit-submitted-street-address-2-bldg-apt-suite-etc {
		width: 345px;
	}
	#edit-submitted-first-name, #edit-submitted-last-name {
		width: 170px;
	}
	#webform-component-comments{
		margin-top: 14px;
	}
}


/* X-Ray Photo Contest */

#webform-client-form-811 {
  span.form-required {
    display: none;
  }
  #webform-component-first-name,
  #webform-component-last-name,
  #webform-component-street-address,
  #webform-component-city,
  #webform-component-state{
    margin-right: 5px;
    display: inline-block;
    float: left;
  }
  #webform-component-state {
    width: 120px;
  }
  label[for=edit-submitted-terms] {
    float: left;
    margin-top: 5px;
  }
  label[for=edit-submitted-terms-1] {
    float: left;
  }
}

#webform-client-form-934 .webform-component-textfield {
  width: 48%;
  float:left;
  margin-right:2%;
}
#webform-client-form-934 .webform-component-textfield input[type=text]{
  width:100%;
  padding-right:0px 10px;
}
#webform-client-form-934 #edit-submitted-how-many-doctors-in-your-practice{
  width:48%;
}
#webform-client-form-934 #edit-submitted-what-days-is-your-office-open-check-all-that-apply input[type=checkbox]{
  height: 12px
}
#webform-client-form-934 fieldset{
  margin: 20px 0px
}
#webform-client-form-934 #webform-component-do-you-exclusively-use-garrison .webform-component-select{
  float:left;
  clear:left;
  margin-right: 2%;
}
#webform-client-form-934 #webform-component-do-you-use-a-caries-detector{
  float:left;
}
#webform-client-form-934 #webform-component-what-improvements-changes-would-you-like-to-see-in-the-garrison-products-you-use{
  clear:both;
}
#webform-client-form-934 #webform-component-what-improvements-changes-would-you-like-to-see-in-the-garrison-products-you-use--improvement-change{
  clear:both;
}
