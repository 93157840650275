
.page-quick-order {
	#page-title {
		font-style: italic;
		color: $primary-color;

	}
}

body.section-express-order #page-title {
	background-image: url("../images/express-order.png");
	background-repeat: no-repeat;
	background-position: right center;
	display: inline-block;
	padding-right: 32px;
}

// #recent-orders-form,
// #express-order-form,
// #cart-form-pane,
// .gd-form,
.section-store .view-uc-catalog {
	overflow: hidden;
	clear: both;

	fieldset {
		border: none;
		padding-left: 0;
		padding-right: 0;
		&.collapsible {
			.fieldset-legend {
				padding-left: 0;
				background-position: 10px 60%;
			}
			table {
				margin-top: 0;
			}
			a.fieldset-title {
				@include wireframe-btn;
				padding: 0 14px 0 24px ;
				background-color: transparent;
			}
		}
	}
	legend, .order-title {
		font-size: 17px;
		letter-spacing: .03em;
		font-weight: bold;
		padding-left: 6px;
		color: $color-grey-mid;
		text-transform: uppercase;
		margin-bottom: 6px;
		margin-top: 12px;
		a {
			color: $color-grey-dark;
			&:hover, &:active {
				color: $link-color-hover;
			}
		}
	}
	table {
		font-size: 14px;
		color: $color-grey-mid;
      width: 100%;
	}
	thead {
		background-color: $color-orange;
		color: #fff;
		font-weight: normal;
	}
	th {
		padding-left: 6px;
		border-bottom: 4px solid transparent;
		a {
			color: #fff;
			text-decoration: underline;
		}
	}
	tbody {
		font-size: 17px;
		border-top: none;
		border-bottom: none;
	}
	tr.even, tr.odd {
		background-color: #fff;
		border-bottom: 1px solid $border-color;
		border-top: 1px solid $border-color;
		padding: 0;
	}
	td {
		padding: 4px 6px;
		vertical-align: middle;
      min-width: 65px;
	}
	img {
		border: 1px solid $border-color;
      padding-right: 0px;
	}
	abbr[title] {
		border-bottom: none;
	}
	input[type="submit"] {
		float: right;
		clear: both;
	}
  .attributes{
    label{
      display: none;
    }
  }

} // End Custom Form

//

// View Order Page
#uc-cart-view-form {
input[type="submit"] {
float: none;
clear: none;
}
}

input[value="Checkout"] {
	@include submit-btn($secondary-color);
}

/* Express Order Form */

#express-order-form {
	input[type="text"] {
		width: 60px;
	}
	table {
		margin-top: 0;
	}
	img {
		display: block;
	}
	th:nth-child(1n+2) {
		text-align: center;
	}
	.form-submit {
		margin-bottom: 36px;
		float: right;
	}
}

.express-order-description {
}

//Hide hidden taxonomy items on express order form

#express-order-form {
  #edit-94 {
  display: none;
  }
}

.express-order-price {
	color: $color-orange;
	font-weight: bold;
	font-weight: 700;
}

//
// Discounts table
//
#cart-form-pane{
   table.uc-discounts-cart-pane-table{
      border: 1px dashed #e3e3e3;
      tr.even,
      tr.odd{
         border: none;
         td{
            // font-style: italic;
         }

      }
   }
   .uc-discounts-cart-pane-container{
      border: none;
   }
   input[type="submit"] {
      margin-right: 0px;
      margin-left: 10px;
      margin-top:10px;
   }
   .form-actions{
      margin-bottom: 40px;
   }
}
//
// Hide payment other text
//
.payment-details-other{
   font-size: 1px;
   color: white;
}
#payment-pane{
   .form-radio{
      margin-top: 10px;
      height:14px;
   }
}

//
// Fix Review Order Page
//
.page-cart-checkout-review{
   #review-instructions{
      padding-bottom: 25px;

   }
}
.order-review-table .review-button-row td{
   padding-bottom: 1em;
}

.form-item-panes-payment-details-cc-number {
	overflow: hidden;
}

.payment-details-credit .form-item-panes-payment-details-cc-exp-month {
	div.selector {
		float: left;
	}
}

//
// Multi-product System/Product Line
//
#block-multiproductlist-multi-list{
   .express-order-description{
      .body,
      .discount{
         font-size: 14px;
      }
      .discount{
         padding-left: 8px;
      }
   }
}

//
// Checkout panes - address
//
#delivery-address-pane,
#billing-address-pane{
   .phone-format{
      margin-left: 227px;
   }
}

//
// Events table
//
.view-Events td{
   min-width: 40px;
}

//
// Checkout panes - cart contents
//
.uc-cart-checkout-form{
  #cart-pane{
    margin-bottom: 40px;

    .fieldset-legend{
      font-weight: bold;
      font-size: 15px;
    }
}}

.address-validation {
	margin: 20px;
	border: solid 1px #ccc;
  	padding: 10px;
  .suggested-address-button {
  	color:#f04e23;
  	cursor:pointer;
  	font-size:0.9em;
  	font-weight:500;
  	margin-left:3px;
  	text-decoration:underline;
  	&:hover {
  		color:darken(#f04e23, 10%);
  	}
  }
}

#ups-validation-modal-wrapper{
  position: fixed;
  background: rgba(0, 0, 0, 0.5);
  top: 0;
  height: 100%;
  left: 0;
  right: 0;
  z-index: 1000;
  width: 100%;

  #ups-validation-modal{
    position: relative;
    width: 500px;
    height: 300px;
    background: white;
    margin: 0px auto;
    margin-top: 100px;

    padding: 20px;

    .loading{
      width: 64px;
      margin: 0px auto;
      margin-top: 100px;
    }

    .right,
    .left{
      float: left;;
      width: 230px;
    }
    .left{
      padding: 0px 10px 0px 0px;
    }
    .right{
      padding: 0px 0px 0px 10px;
    }

    .address{
      padding: 5px;
      border: 1px solid #aaa;
      background: #eee;
      color: #999;

      button{
        margin-top: 10px;
        padding: 8px;
        width: 100%;
        background-color: #aaa;
        border: 0px;
        color: white;
        text-transform: uppercase;
        line-height:18px;
      }

      &.selected{
        color: black;
        border: 1px solid #f04e23;
        background: rgba(#f04e23, 0.3);
      }
    }
  }
  .could-not-find-address{
    button{
      &.back,
      &.continue{
        bottom: 20px;
        position: absolute;
        padding: 5px 20px !important;
        line-height: 18px !important;
        text-transform: uppercase;
      }
      &.back{
        left: 20px;
      }
      &.continue{
        right: 20px;
      }
    }
  }
}

.cart-review tbody tr td.price:before {
	content: attr(data-before);
}
