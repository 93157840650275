#block-block-27 {
  // float: left;
  width: 250px;
  display: inline-block;
  vertical-align: middle;
  @include breakpoint($tablet-small-max) {
    display: none;
  }
}

.main-logo {
  display: block;
  line-height:0;
  padding: $gutter 0;
  #light-logo {
    display: none;
  }
  img {
    width: 100%;
    height: auto;
  }
}


//main navigation styles

#navigation {
  margin: 0px;

  .menu-block-wrapper {
    text-transform: uppercase;
    font-size: 15px;
    @include clearfix;
    padding: $gutter*2 0;
    // max-width: calc(100% - 250px);
    // float: right;

    ul.menu {
      // float: right;
      text-align: right;

      li {
        padding: 0 0 0 $gutter*1.25;
        display: inline-block;
        float: none;
      }

      a, a:link, a:visited {
        color: $secondary-color;
        font-weight: bold;
        vertical-align: middle;
        font-family: $source-sans-pro;
      }

      a:hover, a:active, a.active, a.active-trail {
        color: $primary-color;
      }
    }
  }
  @include breakpoint($tablet-small-max) {
    .menu-block-wrapper {
      ul.menu {
        text-align: left;
        li {
          display: block;
          width: 100%;
          padding: 0;
          border-top: 1px solid $tertiary-color;

          a {
            display: block;
            padding: $gutter;
            color: white !important;
          }
        }
      }
    }
  }
}

// Secondary menu styles

#block-menu-secondary-menu {
  font-weight: 400;
  font-family: $source-sans-pro;
  font-size: 14px;
  margin: 0;
  padding-top: 4px;
  ul.menu {
    margin: 0;
    padding: 0 $gutter 0 0;
  }

  a, a:link, a:visited {
    color: $color-dark-blue;
  }

  a:hover, a:active {
    color: $primary-color;
  }

  li a {
    padding-right: 20px;
    background-repeat: no-repeat;
    background-position: left center;
    float: left;
  }

  li.menu-2333 a,
  li.menu-3076 a,
  li.menu-3077 a,
  li.menu-3078 a,
  li.menu-3079 a {
    display: block;
    background-image: url("../images/chat-now.png");
    padding-left: 25px;
  }

  li.menu-2335 a,
  li.menu-3080 a,
  li.menu-3081 a,
  li.menu-3082 a,
  li.menu-3083 a {
    display: block;
    // background-image: url("../images/check-out.png");
    // padding-left: 30px;
    &:before {
      content: '\f07a';
      font-family: fontAwesome;
      padding-right: 6px;
    }
  }

  li.menu-2419 a,
  li.menu-3084 a,
  li.menu-3085 a,
  li.menu-3086 a,
  li.menu-3087 a {
    display: block;
    // background-image: url("../images/express-order.png");
    // padding-left: 30px;
    &:before {
      content: '\f005';
      font-family: fontAwesome;
      padding-right: 6px;
    }
  }

  li.menu-3205 a {
    display: block;
    &:before {
      content: '\f007';
      font-family: fontAwesome;
      padding-right: 6px;
    }
  }

  li.menu-4 a {
    display: block;
    &:before {
      content: '\f090';
      font-family: fontAwesome;
      padding-right: 6px;
    }
  }

  .last {
    a {
      padding: 0px;
    }
  }
  @include breakpoint($tablet-small-min) {
    float: right;
    li {
      display: inline-block;
    }
  }
  @include breakpoint($tablet-small-max) {
    position: fixed !important;
    width:75vw;
    top: 0;
    left: -75vw;
    transition: all 0.25s ease;
    background: $secondary-color;
    z-index: 999;
    visibility: visible !important;
    ul.menu {
      padding: $gutter/2 0;
      display: block;
      li {
        float: none;
        width: 100%;
        a {
          color: white;
          display: block;
          width: 100%;
          padding: $gutter/2 $gutter;
          float: none;
        }
      }
    }
  }
}

//Products Menu

#block-menu-menu-products {
  ul.menu {
    padding: 0;
    margin: 0;
    display: flex;
    background: $color-grey-light;

    li {
      vertical-align: middle;
      display: inline-block;
      border-right: 1px solid $color-grey;
      width: 20%;
      text-align: center;
      &:last-child {
        border-right: 0px;
      }

      a, a:link, a:visited {
        margin: 0px;
        display: block;
        color: $color-dark-blue;
        text-transform: uppercase;
        font-family: $oswald;
        font-size:1.25em;
        padding: $gutter*0.75 0;
      }
      a.active, a:hover {
        color: $primary-color;
        text-decoration: none;
      }
    }
  }
  @include breakpoint($tablet-portrait-max) {
    ul.menu {
      flex-wrap: wrap;
      border-top: 1px solid $tertiary-color;
      li {
        width: 100%;
        border-right: 0px;
        border-bottom: 1px solid $tertiary-color;
      }
    }
  }
}

// Sidebar product menu styles

.sidebar {
  #block-menu-menu-products {
    h2 {
      display: block !important;
    }
    ul.menu {
      display: block;
      background: none;

      li {
        display: block;
        border-right: 0px;
        width: 100%;
        text-align: left;

        a, a:link, a:visited {
          margin: 0px;
          display: block;
          color: $primary-color;
          text-transform: none;
          font-family: $source-sans-pro;
          font-size:1.1em;
          padding: $gutter/4 0;
        }
        a.active, a:hover {
          color: $secondary-color;
        }
      }
    }
  }
}


// Footer Menu

#block-menu-menu-footer-menu {
  li {
    list-style-type: none;
  }
}


// Breadcrumbs

.breadcrumb {
  border-bottom: 1px solid $primary-color;
  padding-bottom: $gutter/4;
  width: 100%;
  margin: $gutter 0 $gutter*2;
  font-weight: 300;

  ol {
    font-size: 12px;
    color: $tertiary-color;

    a {
      font-weight: 300;
      color: $tertiary-color;

      &:hover, &:active, &:focus {
        color: $link-color-hover;
      }
    }
    li:last-child a {
      font-weight: 700;
    }
  }
}

//Addthis Share button
#social-media {

  .addthis_toolbox {
    position: relative;
    & > a > a {
      background: none;
      color: $tertiary-color;
      padding: 0;
      &:before {
        content:'\f0fe';
        font-family: fontAwesome;
        display: inline-block;
        padding-right: 5px;
      }
      &:hover {
        color: $primary-color;
      }
    }
  }
  @include breakpoint($tablet-small-min) {
    float: right;
    height: 0px;
    .addthis_toolbox {
      top: -$gutter*3.25;
    }
  }
  @include breakpoint($tablet-small-max) {
    .addthis_toolbox {
      top: -$gutter*1.75;
    }
  }
}

//Learning Center navigation

#lcenter-nav {
  font-weight: 300;
  a {
    font-weight: 300;
    letter-spacing: .05em;
  }
  .breadcrumb a {
    letter-spacing: normal;
  }
  h2 {
    background-color: $color-grey-light;
    padding:$gutter/2 $gutter;
    margin: 0;
    color: #ffffff;
    a {
      font-weight: normal;
      display: block;
      color: $secondary-color;
      &:visited, &:hover, &:focus, &:active {
        color: $secondary-color;
      }
    }
  }
  ul.links {
    margin: 1px 0px;
    padding: 0;
    overflow: hidden;
    width: 100%;
    display: table;
    text-align: center;

    li {
      display: table-cell;
      border-right: 1px solid #FFF;
      a {
        padding: $gutter/4;
      }

      &.last {
        border-right: 0px;
            // a{
            //    padding: 0px 18px;
            // }
      }

/*    float: left;
      margin-right: 1px;
      list-style-type: none;
      list-style-image: none;

      &.menu-2581{width: 130px;}
      &.menu-2548{width: 219px;}
      &.menu-2583{width: 168px;}
      &.menu-2785{width: 108px;}
      &.menu-2559{width: 88px;}

      &.menu-2876{width: 130px;}
      &.menu-2884{width: 219px;}
      &.menu-2888{width: 168px;}
      &.menu-2892{width: 108px;}
      &.menu-2896{width: 88px;}

      &.menu-2877{width: 130px;}
      &.menu-2885{width: 219px;}
      &.menu-2889{width: 168px;}
      &.menu-2893{width: 108px;}
      &.menu-2897{width: 88px;}

      &.menu-2878{width: 130px;}
      &.menu-2886{width: 219px;}
      &.menu-2890{width: 168px;}
      &.menu-2894{width: 108px;}
      &.menu-2898{width: 88px;}

      &.menu-2879{width: 130px;}
      &.menu-2887{width: 219px;}
      &.menu-2891{width: 168px;}
      &.menu-2895{width: 108px;}
      &.menu-2899{width: 88px;} */

    }
    a {
      text-transform: uppercase;
      display: block;
      background-color: $tertiary-color;
      color: #fff;
      &:visited, &:hover, &:focus, &:active {
        color: #fff;
      }
      &:hover, &:active, &.active-trail {
        background-color: $primary-color;
      }
    }
  }
  .page__title {
    margin-top: $gutter*2;
  }
  @include breakpoint($desktop-small-max) {
    ul.links li {
      display: block;
      width: 100%;
      border-right: 0px;
      border-bottom: 1px solid white;
    }
  }
}

// MOBILE MENU

//main navigation block

#block-menu-block-3 {
  // float: right;
  width: calc(100% - 254px);
  @include clearfix;
  display: inline-block;
  vertical-align: middle;
  #tablet-menu-toggle {
    display: none;
  }
  @media (max-width:1079px) and (min-width:840px) {
    // padding-right: $gutter;
  }
  @media (max-width:839px) and (min-width:600px) {
    vertical-align: top;
    #tablet-menu-toggle {
      display: block;
      position: absolute;
      right: 0;
      top: $gutter;
      &:hover {
        cursor: pointer;
        &:before {
          background: $primary-color;
        }
      }
      &:before {
        content: '\f0c9';
        display: block !important;
        font-family: fontAwesome;
        display: block !important;
        color: white;
        background: $secondary-color;
        font-size: 1em;
        line-height:2em;
        width:$gutter*1.5;
        height: $gutter*1.5;
        text-align: center; 
        float: right;
      }
    }
    .menu-block-wrapper {
      display: none;

      &.show-main-nav {
        display: block;
        ul.menu {
          position: absolute;
          top: 50px;
          right: 0;
          background: $secondary-color;
          float: right;
          z-index: 999;
          li {
            display: block;
            width: 100%;
            text-align: left;
            border-bottom: 1px solid $color-grey-light;
            padding: 0;
            &:last-child {
              border-bottom: 0px;
            }
            a {
              padding: $gutter/2 $gutter;
              display: block;
              color: white;
              &:hover {
                color: $primary-color;
              }
            }
          }
        }
      }
    }
  }   
  @include breakpoint($tablet-small-max) {
    position: fixed;
    width:75vw;
    top: 159px;
    transition: all 0.25s ease;
    left: -75vw;
    height: calc(100vh - 159px);
    background: $secondary-color;
    z-index: 1000;
  }
}

.mobile-menu-bar {
  @include breakpoint($tablet-small-min) {
    display: none;
  }
  display: block;
  width: 100%;
  background: white;
  z-index: 99;
  @include clearfix;
  #mobile-home {
    display: block;
    width: 100%;
    padding: $gutter;
    text-align: center;
    margin: 0 auto;
    img {
      width:80%;
      max-width: 250px;
      height: auto;
    }
  }
  #mobile-menu {
    display: block;
    float: left;
    width:75%;
    background: $secondary-color;
    color: white !important;
    padding: $gutter/2 $gutter;
    font-family: $oswald;
    text-transform: uppercase;
    font-size: 16px;
    height: 40px;
    &:before {
      content: '\f0c9';
      font-family: fontAwesome;
      display: inline-block;
      padding-right: $gutter/4;
    }
  }
  #mobile-search {
    display: block;
    float: left;
    width: 25%;
    background: $tertiary-color;
    padding: $gutter/2;
    color: white !important;
    text-align: center;
    font-size: 16px;
    height: 40px;
    &:before {
      content: '\f002';
      font-family: fontAwesome;
      display: inline-block;
      padding-right: $gutter/4;
    } 
  }
}

#page {
  transition: all 0.25s ease;
  position: relative;
}

@include breakpoint($tablet-small-max) {
  .show-nav {
    overflow: hidden;
    position: relative;
    #page {
      left: 75vw;
      width: 25vw;
      overflow: hidden;
      & > div {
        width: 100vw;
      }
    }
    #block-menu-block-3,
    #block-menu-secondary-menu {
      left: 0;
    }
    #mobile-menu {
      background-color: $tertiary-color;
      &:before {
        content: '\f00d';
      }
    }
  }
}