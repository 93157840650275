a {
  &:hover, &:active, &:focus {
    text-decoration: none !important;
  }
  color: $link-color;
  text-decoration: none;
  font-weight: 400;
  transition: color 0.1s ease-in;

  &:hover, &:active, &:focus {
    text-decoration: underline;
  }
}

a:visited {
  color: $link-color-visited;
}

a:hover {
  color: $link-color-hover;
}

a:focus {
  color: $link-color-focus;
}

a:active {
  color: $link-color-active;
}

.sidebar-link,
.sidebar-link:visited {
  font-family: $oswald;
  color: white;
  display: block;
  width: 100%;
  padding: $gutter/2 $gutter;
  background-color: $tertiary-color;
  text-align: center;
  font-size: 1.2em;
  text-transform: uppercase;
  font-weight: normal;
  transition: all 0.25s ease;
  &:hover {
    background-color: $primary-color;
    color: white;
    cursor: pointer;
  }
  &:active {
    color: white;
    background-color: $secondary-color;
  }
}