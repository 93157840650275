//dropdowns

div.selector {
  cursor: pointer;
  background-color: $tertiary-color;
  position: relative;
  padding: $gutter/4 $gutter/2;
  line-height: 18px;
  font-size: 14px;
  overflow: hidden;
  color: white;
  height: auto !important;
  background-image: none !important;
  background-position: -99999em !important;
  &:after {
    content: '\f107';
    font-family: fontAwesome;
    position: absolute;
    right: 8px;
    top: 8px;
    color: white;
    font-size: 16px;
    pointer-events: none;
  }
  &.hover {
    background-position: right -16px;
    &:after {
      color: $secondary-color;
    }
  }
  &.focus, &.hover.active {
    background-color: $primary-color;
  }
  > span {
    text-overflow: ellipsis;
    text-shadow: none;
    color: white;
    display: block;
    overflow: hidden;
    white-space: nowrap;
    background-image: none !important;
    background-position: -99999em !important;
    cursor: pointer;
    width: calc(100% - 15px) !important;
    padding-right: 0px;
    font-weight: 400;
    // &:hover, &:focus {
    //   background-image: none !important;
    //   background-position: -99999em !important;
    // }
  }
  select {
    cursor: pointer;
    opacity: 0;
    filter: alpha(opacity=0);
    -moz-opacity: 0;
    border: none;
    background: none !important;
    position: absolute;
    height: $input-height - 3;
    top: 0;
    left: 0px;
    width: 100%;
  }
}


// Inputs

input[type="number"],
.form-item-qty input[type="text"],
.form-type-uc-quantity input,
[class*="form-item-multiproductlist-"] input {
  width:  $xsmall-width;
  text-align: center;
}

input[type="submit"], .btn-submit {
  @include submit-btn;
}


.form-actions input {
  margin-right: 1em;
}

body.page-node-add input[type="submit"] {
  width: auto;
}

#cart-form-pane .remove input[type="submit"] {
  margin-left: 0;
  margin-top: 0;
  height: 22px;
}

#webform-component-edit-form { /* adding spacing to webform submit bottom */
  input [type="submit"] {
    margin-top: 1em;
  }
}

input,
textarea {
  color: $body-font-color;
  background: $input-select-color;
  border: 1px solid $border-color;
  box-shadow: 0 1px 4px $box-shadow-color inset;
  box-shadow: none;
  border-radius: $input-border-radius;
  box-sizing: border-box;
  transition: all 0.2s ease-in;
  padding: 0 .5em;
  max-width: 100%; /* fixed overflow of input fields in drupal 6 layouts */
  /* IE7
  *padding-top: 2px;
  *padding-bottom: 1px;
  *height: auto; */
  &:focus {
   border: 1px solid $input-focus-color;
   background: #fff;
   outline: none;
  }
}

input {
  height: $input-height;
}

input[type="date"],
input[type="datetime"],
input[type="datetime-local"],
input[type="month"],
input[type="password"],
input[type="search"],
input[type="tel"],
input[type="time"],
input[type="week"],
input[type="text"] {
  width: $medium-width;
}

input[type="email"],
input[type="url"],
input[type="passoword"],
.form-item-title .form-text,
#user-login .form-text,
.field-speaker-add-more-wrapper .form-text {
  width: $xlarge-width;
}

 #field-target-url-add-more-wrapper .form-text,
 .form-item-title .form-text {
  width: $xxlarge-width;
}

// Checkboxes

input[type=checkbox] {
  display:none;
  & + label:before {
      content: "";  
      display: inline-block;  
      width: 16px;  
      height: 16px;  
      vertical-align:middle;
      margin-right: 8px; 
      position: relative;
      top: -2px; 
      background-color: white;   
      border:1px solid $primary-color; 
  }
  & + label:hover {
    cursor: pointer;
  }
  & + label:hover:before {
    background-color: $color-grey-light !important;
  }
}
input[type=checkbox]:checked {
  & + label {
    // font-weight: bold !important;
    &:before {
      content:"\f00c"; /* Tick */
        font-family:FontAwesome;
        font-size: .75rem;
        text-align:center;
        line-height:16px;
        color:white;
        // font-weight: normal !important;
        background-color: $primary-color !important;
    }
  }
}

// Radio button style

input[type=radio] {
  display:none;
  & + label:before {
    content: "";  
    display: inline-block;  
    width: 16px;  
    height: 16px;  
    vertical-align:middle;
    margin-right: 8px; 
    position: relative;
    top: -2px; 
    background-color: white;   
    border:1px solid $primary-color; 
    border-radius: 50%;
  }
  & + label:hover {
    cursor: pointer;
  }
  & + label:hover:before {
    background-color: $color-grey-light !important;
  }
  &:checked {
    & + label {
      // font-weight: bold !important;
      &:before {
        content:"\f111"; /* Tick */
          font-family:FontAwesome;
          font-size: 8px;
          text-align:center;
          line-height:16px;
          color:white;
          // font-weight: normal !important;
          background-color: $primary-color !important;
      }
    }
  }
  &:disabled {
    & + label:before {  
      border:1px solid darken($color-grey-light, 10%); 
    }
    & + label:hover {
      cursor: default;
    }
    & + label:hover:before {
      background-color: white !important;
    }
    &:checked {
      & + label {
        &:before {
          content:"\f111";
            font-family:FontAwesome;
            font-size: 8px;
            text-align:center;
            line-height:16px;
            color:white;
            background-color: darken($color-grey-light, 10%) !important;
        }
      }
    }
  }
}