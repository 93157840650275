//Global table styles

table {
  color: white;
  width: 100%;
  font-size: 14px;

  thead {
    background-color: $tertiary-color;
    color: $color-white;
    text-transform: uppercase;
  }

  th,
  td {
    padding: $gutter/4 $gutter/2;
    vertical-align: middle;
  }

  tbody {
    tr {
      border-bottom: 1px solid $tertiary-color;

      &:last-child {
        border-bottom: 0px;
      }
    }

    tr.odd {
      background-color: $secondary-color;
    }

    tr.even {
      background-color: lighten($secondary-color, 10%);
    }
  }

  input[type="text"] {
    width: 50px;
  }
}

//Cart table

#cart-form-pane {
  width: 100%;

  .subtotal {
    background: $color-grey-light;
    color: $primary-color;
    text-transform: uppercase;
    width: 100%;
  }

  #uc-cart-view-form td {
    vertical-align: middle !important;
  }

  .desc {
    font-size: 1.2em;
  }
}

//Checkout table
#cart-pane {
  th.products {
    text-align: left;
  }
}

//Checkout total preview table
#line-items-div {
  #uc-order-total-preview {
    width: 100% !important;

    tr:nth-child(odd) {
      background: $secondary-color;
    }

    tr:nth-child(even) {
      background: lighten($secondary-color, 10%);
    }

    input [type="text"] {
      margin: 0;
    }
  }

  @include breakpoint($tablet-small-max) {
    display: block;
    width: 100% !important;
    float: left;
    margin-left: 0px !important;
    margin-right: 0px !important;

    tr {
      width: 100% !important;
    }
  }
}

.gd-form,
#express-order-form,
#recent-orders-form {
  overflow: hidden;
  clear: both;

  .selector {
    width: 100% !important;
  }

  td:last-child .form-item {
    margin-top: 5px;
  }

  fieldset {
    border: none;
    padding-left: 0;
    padding-right: 0;

    &.collapsible {
      .fieldset-legend {
        padding-left: 0;
        background-position: 10px 60%;
      }

      table {
        margin-top: 0;
      }

      a.fieldset-title {
        @include wireframe-btn;
        padding: 0 14px 0 24px;
        background-color: transparent;
      }
    }
  }

  legend,
  .order-title {
    font-size: 17px;
    letter-spacing: .03em;
    font-weight: bold;
    padding-left: 6px;
    color: $color-grey-mid;
    text-transform: uppercase;
    margin-bottom: 6px;
    margin-top: 12px;

    a {
      color: $color-grey-dark;

      &:hover,
      &:active {
        color: $link-color-hover;
      }
    }
  }

  th {
    a {
      color: #fff;
      text-decoration: underline;
    }
  }

  td {
    min-width: 65px;
  }

  abbr[title] {
    border-bottom: none;
  }

  input[type="submit"] {
    float: right;
    clear: both;
  }

  .attributes {
    label {
      display: none;
    }
  }

  td.sku {
    font-weight: bold;
    min-width: 100px;
  }

}

// End Custom Form


.order-review-table {
  max-width: 100%ß;

  tr {
    color: black !important;
  }

  .pane-title-row {
    background-color: $tertiary-color !important;
    color: $color-white !important;
    text-transform: uppercase;
  }
}


@include breakpoint($desktop-min) {

  #express-order-form,
  #recent-orders-form {
    th:last-child {
      text-align: right;
    }

    tr {
      td:last-child {
        text-align: right;
      }
    }
  }

  .gd-form {

    th:first-child,
    tr td:first-child {
      width: 120px;
    }
  }
}

@include breakpoint($desktop-max) {

  .gd-form,
  #express-order-form,
  #recent-orders-form {
    thead {
      display: none !important;
    }

    tr {
      border-bottom: $gutter/2 solid white;
      display: inline-block;
      width: 100%;

      td {
        padding: $gutter/2;
      }

      td:first-child {
        float: left;
        width: 80px;
        display: block;
      }

      td.express-order-description {
        float: right;
        width: calc(100% - 80px);
        display: block;
        // border-left: 1px solid $tertiary-color;
      }

      td.sku,
      td.express-order-attr,
      td.express-order-price,
      td:last-child {
        float: left;
        width: 25%;

        // height:70px;
        &:before {
          text-transform: uppercase;
          font-weight: bold;
          font-size: 0.8em;
          display: block;
          color: white;
        }
      }

      td.sku {
        clear: both;
        border-top: 1px solid $tertiary-color;

        // border-right: 1px solid $tertiary-color;
        &:before {
          content: 'SKU';
        }
      }

      td.express-order-attr {
        border-top: 1px solid $tertiary-color;

        // border-right: 1px solid $tertiary-color;
        &:before {
          content: 'Size';
        }
      }

      td.express-order-price {
        border-top: 1px solid $tertiary-color;

        // border-right: 1px solid $tertiary-color;
        &:before {
          content: 'Price';
        }
      }

      td:last-child {
        border-top: 1px solid $tertiary-color;

        &:before {
          content: 'Quantity';
        }
      }
    }
  }
}

@include breakpoint($tablet-small-max) {
  #cart-form-pane {
    display: block;

    thead {
      display: none !important;
    }

    tbody {
      display: block;
      width: 100%;
    }

    tr {
      display: inline-block;
      width: 100%;
      border-bottom: $gutter/2 solid white;
    }

    td {
      padding: $gutter/2;
      display: block;
    }

    td.remove {
      width: 70px;
      float: right;

    }

    td.image {
      width: 80px;
      float: left;
    }

    td.desc {
      float: left;
      width: calc(100% - 150px);
    }

    td.qty,
    td.price {
      float: left;
      border-top: 1px solid $tertiary-color;

      &:before {
        content: '';
        color: white;
        text-transform: uppercase;
        font-weight: bold;
        font-family: $source-sans-pro;
        display: block;
      }
    }

    td.qty {
      text-align: right;
      clear: both;
      width: 75%;

      &:before {
        content: 'QTY';
      }
    }

    td.price {
      text-align: right;
      width: 25%;

      &:before {
        content: 'Price';
      }
    }
  }

  .gd-form,
  #express-order-form,
  #recent-orders-form {
    tr {

      td.sku,
      td.express-order-attr,
      td.express-order-price,
      td:last-child {
        width: 50%;
      }

      td.express-order-price {
        clear: both;
      }
    }
  }
}

.node-type-system-product-line {

  .gd-form {
    th:first-child {
      text-align: left;
    }

    @include breakpoint($desktop-max) {
      td:last-child {
        border-top: none;
        width: auto;

        &:before {
          display: none;
        }
      }
    }
  }
}
